.nav__link {
  display: flex;
  align-items: center;
  padding: 1rem 1rem calc(1rem - 2px);
  color: white;
  text-decoration: none;
  transition: 0.2s all;
  border-bottom: 2px solid transparent;
  text-transform: uppercase;

  &:hover {
    transform: scale(1.05);
  }

  &:active, &:focus, &--active {
    border-bottom-color: white;
    outline: none;
  }

  &:hover, &:active, &:focus, &--active {
    background: rgba(255,255,255,0.25);
  }

  &:first-child {
    margin: 0;
  }
}